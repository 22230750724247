import json from './environment.data.json';
import { NextRelease } from 'semantic-release';

type EnvironmentData = (Pick<NextRelease, 'type' | 'channel' | 'version'> & { build: number | null }) | Record<string, never>;
const data = json as EnvironmentData | Record<string, never>;

export const build: number | undefined = data?.build ?? undefined;
export const channel: string | undefined = data?.channel;
export const type: string | undefined = data?.type;
export const version: string | undefined = data?.version;

export const isProduction = !data?.channel;
export const sentryRelease = data?.version && data?.build
    ? `app.plant-manager.app@${data.version}+${data.build}`
    : undefined;
