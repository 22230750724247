import { createApp } from 'vue';
import { createPinia } from 'pinia';
import { IonicVue } from '@ionic/vue';
import { init } from '@sentry/capacitor';
import { vueRouterInstrumentation, init as sentryInit, BrowserTracing } from '@sentry/vue';

import App from '@/App.vue';
import router from '@/router';
import { api } from '@/helpers/api';
import { sentryRelease } from '@/helpers/environment';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Fonts */
// import '@fontsource/luckiest-guy';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
// import '@ionic/vue/css/padding.css';
// import '@ionic/vue/css/float-elements.css';
// import '@ionic/vue/css/text-alignment.css';
// import '@ionic/vue/css/text-transformation.css';
// import '@ionic/vue/css/flex-utils.css';
// import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';

const app = createApp(App);
if (sentryRelease) {
    init({
        app,
        dsn: 'https://73f63985abce4c439c5598f720f67e08@glitch.sebbo.net/10',
        environment: location.host,
        release: sentryRelease,
        integrations: [
            new BrowserTracing({
                routingInstrumentation: vueRouterInstrumentation(router)
            })
        ],
        tracesSampleRate: 1.0
    }, sentryInit);
}

const pinia = createPinia();
app.use(IonicVue, { mode: 'ios' })
    .use(router)
    .use(pinia);

api.initialize()
    .then(() => router.isReady())
    .then(() => app.mount('#app'))
    .catch(error => console.error(error));
