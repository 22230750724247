import {
    ApolloCache,
    ApolloClient, ApolloError,
    ApolloLink,
    ApolloQueryResult,
    createHttpLink,
    defaultDataIdFromObject,
    InMemoryCache,
    MutationOptions,
    NormalizedCacheObject,
    OperationVariables,
    QueryOptions,
    StoreObject
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import fragmentMatcher, {
    AuthStoreGardenFragment,
    AuthStoreUserFragment,
    ClaimSessionMutationVariables,
    CreateSessionDocument,
    CreateSessionMutation,
    CreateSessionMutationVariables,
    CreateUserMutationVariables,
    DeleteSessionDocument,
    DeleteSessionMutation,
    DeleteSessionMutationVariables,
    UpdateSessionDocument,
    UpdateSessionMutation,
    UpdateSessionMutationVariables
} from '@/types.api';
import { App } from '@capacitor/app';
import { Storage } from '@ionic/storage';
import { Device } from '@capacitor/device';
import { FetchResult } from '@apollo/client/link/core';
import { CachePersistor, IonicStorageWrapper } from 'apollo3-cache-persist';
import { waitTillNetworkAvailable } from '@/helpers/network';
import typePolicies from '../types.policies.json';
import { logError } from '@/helpers/errors';

export interface AuthStoreSessionLink {
    id: string;
    secret: string;
    expiresAt: string;
}

export interface AuthStoreSession {
    id: string;
    secret: string;
    name: string;
    link: AuthStoreSessionLink | undefined;
}

export type AuthStoreUser = AuthStoreUserFragment;
export type AuthStoreGarden = AuthStoreGardenFragment;

export interface AuthStoreState {
    session: AuthStoreSession | undefined;
    user: AuthStoreUser | undefined;
    gardens: AuthStoreGarden[];
}

class ApiHelper {
    private storage: Storage;
    private state: AuthStoreState | undefined;
    private cache: ApolloCache<NormalizedCacheObject>;
    private persistor: CachePersistor<NormalizedCacheObject> | undefined;
    private client: ApolloClient<NormalizedCacheObject>;

    public static SCHEMA_VERSION_KEY = '__schema-version';
    public static SCHEMA_VERSION = 1;

    constructor () {
        this.storage = new Storage();

        const httpLink = createHttpLink({
            uri: 'https://api.plant-manager.app/api',
            fetch: window.fetch
        });

        const authLink = setContext(() => {
            if (!this.state?.session) {
                return {};
            }

            return {
                headers: {
                    'X-Session-Id': this.state.session.id,
                    'X-Session-Token': this.state.session.secret + '*'
                }
            };
        });

        this.cache = new InMemoryCache({
            dataIdFromObject: this.dataIdFromObject,
            possibleTypes: fragmentMatcher.possibleTypes
        });
        this.client = new ApolloClient({
            link: ApolloLink.from([authLink, httpLink]),
            cache: this.cache
        });
    }

    async initialize () {
        await this.storage.create();
        this.persistor = new CachePersistor({
            cache: this.cache,
            storage: new IonicStorageWrapper(this.storage),
            trigger: persist => {
                App.addListener('pause', persist);
                return () => {};
            },
            debounce: 0,
            debug: true
        });

        await this.restore();
        this.updateSessionOnce();
    }

    public dataIdFromObject (obj: Readonly<StoreObject>): string | undefined {
        const policies = typePolicies as Record<string, { keyFields?: string[] }>;
        if (
            typeof obj.__typename === 'string' &&
            obj.__typename in typePolicies &&
            policies[obj.__typename] !== null &&
            policies[obj.__typename].keyFields
        ) {
            return policies[obj.__typename]
                .keyFields?.map(k => obj[k])
                .join(':');
        }

        return defaultDataIdFromObject(obj);
    }

    get session (): AuthStoreSession & { secret: undefined } | undefined {
        if (this.state?.session) {
            return Object.assign({}, this.state.session, { secret: undefined });
        }

        return undefined;
    }

    get user (): AuthStoreUser | undefined {
        return this.state?.user;
    }

    get gardens (): AuthStoreGarden[] {
        return this.state?.gardens || [];
    }

    assertSession (): void {
        if (!this.state?.session) {
            throw new Error('No session available.');
        }
    }

    assertUser (): void {
        if (!this.state?.user) {
            throw new Error('No user available.');
        }
    }

    private async restore (): Promise<void> {
        if (!this.persistor) {
            throw new Error('Unable to restore: Persistor not initialized, have you called initialize?');
        }

        try {
            const json = await this.storage.get('auth');
            if (json) {
                this.state = JSON.parse(json) as AuthStoreState;
            }
        } catch (error) {
            console.warn(error);
        }

        const currentVersion = await this.storage.get(ApiHelper.SCHEMA_VERSION_KEY);
        if (currentVersion === ApiHelper.SCHEMA_VERSION) {
            await this.persistor.restore();
        }
    }

    private async save (): Promise<void> {
        await this.storage.set('auth', JSON.stringify(this.state));
    }

    private async reset (): Promise<void> {
        if (!this.persistor) {
            throw new Error('Unable to reset: Persistor not initialized, have you called initialize?');
        }

        await this.client.clearStore();
        await this.persistor.purge();

        this.state = {
            session: undefined,
            user: undefined,
            gardens: []
        };

        await this.storage.set(ApiHelper.SCHEMA_VERSION_KEY, ApiHelper.SCHEMA_VERSION);
        await this.save();
    }

    async query<T = unknown, TVariables extends OperationVariables = OperationVariables> (options: QueryOptions<TVariables, T>): Promise<ApolloQueryResult<T>> {
        const response = await this.client.query<T, TVariables>(options);
        if (response.error) {
            throw response.error;
        }

        return response;
    }

    async mutate<TData = unknown, TVariables extends OperationVariables = OperationVariables> (options: MutationOptions<TData, TVariables>): Promise<FetchResult<TData>> {
        const response = await this.client.mutate(options);
        if (response.errors?.length) {
            throw response.errors[0];
        }

        return response;
    }

    async generateSessionInfos (): Promise<CreateSessionMutationVariables> {
        const [device, lang] = await Promise.all([
            Device.getInfo(),
            Device.getLanguageTag()
        ]);

        return {
            name: device.name || device.model,
            model: device.model,
            platform: device.platform,
            os: device.operatingSystem,
            version: undefined,
            language: lang.value
        };
    }

    async createSession (): Promise<void> {
        if (this.state?.session) {
            return;
        }

        const variables = await this.generateSessionInfos();
        const response = await this.mutate<CreateSessionMutation, CreateSessionMutationVariables>({
            mutation: CreateSessionDocument,
            variables
        });
        if (!response?.data?.createSession) {
            throw new Error('Unable to create session: Response empty');
        }

        this.state = {
            session: {
                id: response.data.createSession.id,
                secret: response.data.createSession.secret,
                name: variables.name,
                link: {
                    id: response.data.createSession.link.id,
                    secret: response.data.createSession.link.secret,
                    expiresAt: response.data.createSession.link.expiresAt
                }
            },
            user: undefined,
            gardens: []
        };

        await this.save();
    }

    async updateSession (): Promise<void> {
        this.assertSession();

        const variables = await this.generateSessionInfos();
        const response = await this.mutate<UpdateSessionMutation, UpdateSessionMutationVariables>({
            mutation: UpdateSessionDocument,
            variables
        });
        if (!response?.data?.updateSession) {
            throw new Error('Unable to update session: Response empty');
        }
    }

    updateSessionOnce (): void {
        if (this.state?.session) {
            new Promise(cb => setTimeout(cb, 2000))
                .then(() => waitTillNetworkAvailable())
                .then(() => this.updateSession())
                .catch(error => {
                    if (
                        error instanceof ApolloError &&
                        error.networkError &&
                        'statusCode' in error.networkError &&
                        [401, 403].includes(error.networkError.statusCode)
                    ) {
                        this.reset();
                        return;
                    }

                    logError(error);
                    setTimeout(() => this.updateSessionOnce(), 60000);
                });
        }
    }

    async deleteSession (id = this.state?.session?.id): Promise<void> {
        if (!id) {
            throw new Error('No session given to delete.');
        }
        if (this.state?.user) {
            await this.mutate<DeleteSessionMutation, DeleteSessionMutationVariables>({
                mutation: DeleteSessionDocument,
                variables: { id }
            });
        }

        if (this.state?.session?.id === id) {
            await this.reset();
        }
    }

    async createUser (parameters: CreateUserMutationVariables): Promise<void> {
        // @todo
    }

    async claimSession (parameters: ClaimSessionMutationVariables): Promise<void> {
        // @todo
    }
}

export const api = new ApiHelper();
