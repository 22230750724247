import { AsyncComponentLoader, Component, defineAsyncComponent, inject, provide, ref, Ref } from 'vue';
import { ComponentRef } from '@ionic/core';
import { modalController, ModalOptions } from '@ionic/vue';
import ModalLoader from '@/components/atoms/ModalLoader.vue';


export interface UseModalOptions {
    page?: Ref<HTMLElement | undefined>;
}

type OpenModalOptions<T extends Component> = Omit<ModalOptions<ComponentRef>, 'component'> & {
    component: AsyncComponentLoader<T>
};

export function useModal (options: UseModalOptions) {
    const page = options.page ||
        inject<Ref<HTMLElement>>('helpers.modal.page') ||
        ref(undefined) as Ref<HTMLElement | undefined>;

    function registerPage (_page: Ref<HTMLElement>) {
        page.value = _page.value;
        provide('helpers.modal.page', page);
    }

    function openCardModal<T extends Component> (options: OpenModalOptions<T>) {
        openCardModalAsync(options)
            .catch(error => console.error(error));
    }

    async function openCardModalAsync<T extends Component> (options: OpenModalOptions<T>) {
        const component = defineAsyncComponent({
            loader: options.component,
            loadingComponent: ModalLoader,
            delay: 100
        }) as ComponentRef;

        const presentingElement = await modalController.getTop() || page.value;
        const modal = await modalController.create({
            ...options,
            component,
            presentingElement
        });

        return modal.present();
    }

    return { registerPage, openCardModal, openCardModalAsync };
}
