import { createRouter, createWebHistory } from '@ionic/vue-router';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { RouteRecordRaw, RouterView } from 'vue-router';
import { h } from 'vue';

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        redirect: '/tabs/tab1'
    },
    {
        path: '/onboarding/',
        component: { render: () => h(RouterView) },
        children: [
            {
                path: '',
                name: 'OnboardingWelcome',
                component: () => import('@/views/onboarding/OnboardingWelcome.vue'),
                meta: {
                    public: true
                }
            }/* ,
            {
                path: 'create-account',
                name: 'OnboardingCreateAccount',
                component: () => import('@/views/onboarding/OnboardingCreateAccount.vue'),
                meta: {
                    public: true
                }
            } */
        ]
    },
    {
        path: '/tabs/',
        component: () => import('@/views/TabsPage.vue'),
        children: [
            {
                path: '',
                redirect: '/tabs/tab1'
            },
            {
                path: 'tab1',
                component: () => import('@/views/Tab1Page.vue')
            },
            {
                path: 'tab2',
                component: () => import('@/views/Tab2Page.vue')
            },
            {
                path: 'tab3',
                component: () => import('@/views/Tab3Page.vue')
            }
        ]
    }
];

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes
});

router.beforeEach((to, from, next) => {
    const isPublic = to.matched.some(record => record.meta.public);
    const loggedIn = false;
    console.log('beforeEach', to.path, isPublic);

    if (!isPublic && !loggedIn) {
        return next({
            path: '/onboarding/',
            query: { redirect: to.fullPath }
        });
    }
    if (isPublic && loggedIn) {
        return next('/');
    }

    next();
});

App.addListener('appUrlOpen', function (event: URLOpenListenerEvent) {
    const slug = event.url.split('.plant-manager.app').pop();
    if (slug) {
        router.push({ path: slug });
    }
});

export default router;
